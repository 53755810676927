<template>
    <div class="register">
        <header class="register-title" style="margin-bottom: 30px;">
            <img src="http://www.shininghouse.cn/public/web/images/vipcenter/vip_logo.png" alt="Logo">
        </header>
        <van-form
                @submit="onSubmit"
                @failed="onFailed"
        >
            <div class="register-form" style="margin-bottom: 20px;"> 
                <h2 class="heading">
                    建立您的会员账号
                </h2>
                <van-cell :border="false">
                    <van-radio-group
                            v-model="params.gender"
                            bind:change="onChangeGender"
                            direction="horizontal"
                            
                    >
                        <van-radio name="1" shape="square" checked-color="#F6BCB5">女士</van-radio>
                        <van-radio name="2" shape="square" checked-color="#F6BCB5">男士</van-radio>
                    </van-radio-group>
                </van-cell>
                <van-cell>
                    <van-field
                            v-model="params.name"
                            label="姓名"
                            placeholder="请输入姓名"
                            :rules="[{ required: true }]"
                    />
                </van-cell>
                <van-cell>
                    <van-field
                            v-model="params.area"
                            label="地区"
                            placeholder="请选择所在地区"
                            :rules="[{ required: true }]"
                            readonly
                            @click="showCascader = true"
                    />
                </van-cell>
                <van-cell>
                    <van-field
                            v-model="params.phone"
                            label="手机"
                            name="手机"
                            type="digit"
                            center
                            clearable
                            placeholder="请输入手机号"
                            maxlength="11"
                            :rules="[{ pattern, message: '请输入正确的手机号' }]"
                    />
                </van-cell> 
                <van-cell>
                    <van-field
                            v-model="params.address"
                            label="详细地址"
                            name="详细地址"
                            type="text"
                            center
                            clearable
                            placeholder="请输入详细地址"
                            :rules="[{required: true}]"
                    />
                </van-cell>
            </div>
            <van-button
                    block
                    native-type="submit"
                    style="background-color: #F6BCB5; color: white;"
            >
                提交
            </van-button>
        </van-form>
        <van-popup v-model:show="showCascader" round position="bottom">
            <van-area
                title="地区"
                :area-list="areaList"
                columns-num="3"
                @confirm="onConfirmArea"
            />
        </van-popup>
    </div>
</template>

<script>
    import moment from 'moment'
    import {mapActions, mapState} from 'vuex'
    import { areaList } from '@vant/area-data';

    const getDefaultParams = () => {
        return {
            device_key: '',
            phone: '',
            name: '',
            gender: "1",
            province_id: 0,
            city_id: 0,
            district_id: 0,
            address: '',
            area: '',
            province: '',
            city: '',
            district: ''
        }
    }

    export default {
        name: "index",
        data() {
            return {
                params: getDefaultParams(),
                imageCaptcha: '',
                loading: false,
                loadingSms: false,
                smsCodeStatus: true,
                sendSmsInterval: 60 * 1000,
                pattern: /^1[3-9]\d{9}$/,
                nickName: '',
                deviceKey: '',
                entranceCode: '',
                areaList,
                showCascader: false
            }
        },
        computed: {
            ...mapState('mysteryBox', [
                'device',
                'user',
                'mysteryBoxCode',
                'expireTime',
                'total',
                'receiveRecords'
            ]),
            areaOptions() {
                let output = []
                let temp = _.values(this.area)
                temp.forEach(({name, code, id}) => output.push({
                    text: name,
                    value: code,
                    id
                }))
                return output
                
            }
        },
        watch: {

        },
        mounted() {
            //判断链接是否有效
            let that = this
            const toast = this.$toast.loading('加载中...')
            this.entranceCode = this.$route.query.entrance_code

            if( !this.entranceCode ) {
                return this.$router.replace({ path : '/404', query: {message: "链接已失效"} })
            } else {
                this.verifyLogin().then((res) => {
                    this.verifyEntranceCode(this.entranceCode).then((res) => {
                        that.params.device_key = that.device.device_key
                    })
                    //判断是不是会员
                    this.getUserInfo().then((res) => {
                        if (JSON.stringify(this.user) === '{}' || JSON.stringify(this.user) === '[]') {
                            //开始注册
                            toast.clear()
                        } else {
                            //判断最近7天有没有领过
                            this.getReceiveRecords({offset: 0, limit: 1}).then((res) => {
                                let canReceive = false
                                if( 0 == this.total ) {
                                    canReceive = true
                                } else {
                                    let lastReceiveRecord = this.receiveRecords[0]
                                    let last7 = moment().subtract('days', 7).format('YYYY-MM-DD HH:mm:ss');
                                    if( moment(lastReceiveRecord.created_at).isBefore(last7) ) {
                                        canReceive = true
                                    } else {
                                        // if( 1 == lastReceiveRecord.status ) {
                                            this.$router.push({ path : '/confirm', query: {mysteryBoxCode: lastReceiveRecord.mystery_box_code, expireTime: lastReceiveRecord.expire_time} })
                                        // } else {
                                        //     return this.$router.replace({ path : '/404', query: {message: "您近期已领取过盲盒码"} })
                                        // }
                                    }
                                }
                                if( canReceive ) {
                                    this.receiveMysteryBoxCode()
                                }
                            })
                        }
                    })
                })
            }
            toast.clear()
        },
        methods: {
            ...mapActions('mysteryBox', [
                'verifyLogin',
                'verifyEntranceCode',
                'getDevice',
                'getUserInfo',
                'register',
                'getReceiveRecords',
                'getMysteryBoxCode',
            ]),
            sendSmsCaptcha() {
                if (!this.smsCodeStatus) {
                    return
                }
                this.loadingSms = true
                this.$store.dispatch('captcha/sms', this.params.phone).then(() => {
                    this.smsCodeStatus = false
                }).catch((err) => {
                    this.$toast.fail(err.message)
                }).finally(() => {
                    this.loadingSms = false
                })
            },
            onSubmit() {
                const toast = this.$toast.loading('加载中...')
                this.$store.dispatch('mysteryBox/register', this.params)
                    .then(() => {
                        toast.clear()
                        this.receiveMysteryBoxCode()
                    })
                    .catch((err) => {
                        toast.clear()
                        this.$toast.fail(err.message)
                    })
            },
            onConfirmArea(res){
                if(res.length < 3) {

                }

                if(Array.isArray(res)) {
                    let province = res[0], city = res[1], district = res[2]
                    this.params = {
                        ...this.params,
                        province: province && province.name,
                        city: city && city.name,
                        district: district && district.name
                    }

                    this.params.area = `${this.params.province} ${this.params.city} ${this.params.district}`
                }
                this.showCascader = false
            },
            finish() {
                this.smsCodeStatus = true
            },
            onFailed() {

            },
            onChangeGender(gender) {
                this.params.gender = gender
            },
            receiveMysteryBoxCode() {
                const toast = this.$toast.loading('请稍后...')
                this.getMysteryBoxCode({device_key: this.params.device_key}).then((res) => {
                    this.$router.push({ path : '/confirm', query: {mysteryBoxCode: this.mysteryBoxCode, expireTime: this.expireTime} })
                }).catch((err) => {
                    console.log(err)
                    this.$message.error(err.message)
                }).finally(() => {
                    toast.clear();
                })
            }
        },
    }
</script>

<style
        lang="scss"
>
    @import "src/design/card";
    @import "src/design/common";

    .register {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -50px;

        .heading {
            font-size: 17px;
            color: #F6BCB5;
        }

        .van-form {
            width: 90%;
        }

        .van-cell {
            padding-left: 0;
        }

        .van-field__label {
            font-size: 15px;
            color: #F6BCB5;
        }

        .van-field__error-message, .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
            color: #F6BCB5; 
        }

        .van-tabs__line {
            background-color:#F6BCB5; 
        }
    }

    .van-cascader__option--selected {
        color: #F6BCB5;
    }
</style>